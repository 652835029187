<template>
  <div>
    <a-modal v-model="visibleModalManageRewardPunishment" class="mod" :title="status.isEdit ? 'Edit Activities ' : 'Create Activities '
      " @cancel="toggleModalManageRewardPunishment" centered>
      <form-activities :activities="newActivities" @handle-change="handleChange" />
      <template slot="footer">
        <a-button size="large" key="back" @click="toggleModalManageRewardPunishment">
          Cancel
        </a-button>
        <a-button size="large" key="submit" type="primary" :loading="loadingActionModalManageRewardPunishment"
          @click="handleOkModalManageRewardPunishment">
          {{ status.isEdit ? "Edit" : "Create" }}
        </a-button>
      </template>
    </a-modal>
    <div class="d-flex">
      <!-- <div class="flex-column flex-md-row mb-4">
        <a-input v-model="search" @keyup="searchRewardPunishment" placeholder="Search by Description..." size="large"
          :style="{
            width: isMobile ? '100%' : '250px',
          }"></a-input>
        <a-select size="large" placeholder="Select Type" :style="{
          'margin-left': !isMobile ? '20px' : '',
          width: isMobile ? '100%' : '120px',
        }" @change="handleSelect" :default-value="selectedType">
          <a-select-option v-for="item in rpTypes" :key="item.key" :value="item.type">{{ item.type
          }}</a-select-option>
        </a-select>
      </div> -->
      <div class="ml-auto">
        <a-button @click.prevent="toggleModalManageRewardPunishment('add')" size="large" type="primary"><a-icon
            type="plus" />Create Activities</a-button>
      </div>
    </div>
    <div class="mt-4">
      <a-table :columns="rewardPunishmentColumns" :data-source="rewardPunishmentDataTable" :pagination="pagination"
        :loading="loadingTable" bordered>
        <!-- <span slot="durasi_maksimal" slot-scope="value, record"> {{value}} {{record.tipe_durasi.toUpperCase()}} </span> -->
        <div class="d-flex flex-column" slot="action" slot-scope="value, record">
          <a-button @click.prevent="toggleModalManageRewardPunishment('edit', record)"
            class="w-100 mb-3 text-warning border border-warning" size="large">
            <a-icon type="edit" />Edit
          </a-button>
          <a-button @click.prevent="handleDeleteRewardPunishment(record)" class="text-danger border border-danger"
            size="large">
            <a-icon type="delete" />Delete
          </a-button>
        </div>
      </a-table>
    </div>
  </div>
</template>
<script>
const FormActivities = () => import('@/components/app/Admin/FormActivities')
const rewardPunishmentColumns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    align: 'center',
    width: 500,
    scopedSlots: {
      customRender: 'description',
      filterIcon: 'filterIcon',
    },
  },
  {
    title: 'Action',
    dataIndex: 'action',
    key: 'action',
    width: 100,
    scopedSlots: { customRender: 'action' },
    align: 'center',
  },
]
export default {
  components: {
    FormActivities,
  },
  data() {
    return {
      rewardPunishmentColumns,
      rewardPunishmentDataTable: [],
      pagination: {},
      loadingTable: false,
      visibleModalManageRewardPunishment: false,
      loadingActionModalManageRewardPunishment: false,
      status: {
        isEdit: false,
        isCreate: false,
      },
      newRewardPunishment: {
        description: null,
        type: null,
        score: null,
      },
      newActivities: {
        name: null,
      },
      idEdit: null,
      selectedType: 'All',
      search: '',
      rpTypes: [
        {
          key: 1,
          type: 'All',
        },
        {
          key: 2,
          type: 'Reward',
        },
        {
          key: 3,
          type: 'Punishment',
        },
      ],
    }
  },
  methods: {
    handleChange(payload) {
      const { value } = payload
      this.newActivities.name = value
    },
    toggleModalManageRewardPunishment(status, data) {
      this.visibleModalManageRewardPunishment = !this.visibleModalManageRewardPunishment
      if (!this.visibleModalManageRewardPunishment) {
        setTimeout(() => {
          this.status = {
            isEdit: false,
            isCreate: false,
          }
          this.newActivities = {
            name: null,
          }
          this.idEdit = null
        }, 500)
        return ''
      }
      if (status) {
        this.status[status === 'add' ? 'isCreate' : 'isEdit'] = true
        if (status === 'edit') {
          this.idEdit = data.key
          this.newActivities = {
            name: data.name,
          }
        }
      }
    },
    handleOkModalManageRewardPunishment() {
      const content = `${this.status.isEdit ? 'Are you sure want edit this reward punishment? your changes will be affected to report system' : 'Are you sure want to create this reward punishment? your changes will be save and affected to report system'}`
      if (this.isValidateForm) {
        this.$notification.warning({
          message: 'Warning',
          description: 'Please fill all column',
        })
      } else {
        this.$confirm({
          title: 'Warning',
          content: (
            <div>{content}</div>
          ),
          onOk: () => {
            this.loadingActionModalManageRewardPunishment = true
            const payload = this.status.isEdit ? {
              id: this.idEdit,
              dataActivities: this.newActivities,
            } : {
              dataActivities: this.newActivities,
            }
            this.$store.dispatch(`admin/${this.status.isEdit ? 'EDIT' : 'POST'}_ACTIVITIES`, payload)
              .then(isSuccess => {
                this.loadingActionModalManageRewardPunishment = false
                this.toggleModalManageRewardPunishment()
                this.fetchDataRewardPunishment()
                if (isSuccess) {
                  const description = this.status.isEdit ? 'Reward Punishment has been edited' : 'Reward Punishment has been created'
                  this.$notification.success({
                    message: 'Success',
                    description,
                  })
                } else {
                  const description = this.status.isEdit ? 'Reward Punishment has not been edited' : 'Reward Punishment has not been created'
                  this.$notification.error({
                    message: 'Error',
                    description,
                  })
                }
              })
          },
          onCancel: () => {
          },
          centered: true,
          icon: 'warning',
          okType: 'primary',
          okText: this.status.isEdit ? 'Edit Now' : 'Create Now',
          cancelText: 'Cancel',
        })
      }
    },
    handleDeleteRewardPunishment(data) {
      this.$confirm({
        title: 'Warning',
        content: (
          <div>Are you sure want to delete this reward punishment ? your changes will be affected to report system</div>
        ),
        onOk: () => {
          this.loadingTable = true
          this.$store.dispatch('admin/DELETE_ACTIVITIES', {
            id: data.key,
          })
            .then(isSuccess => {
              this.fetchDataRewardPunishment()
              if (isSuccess) {
                this.$notification.success({
                  message: 'Success',
                  description:
                    'Activities has been deleted',
                })
              } else {
                this.$notification.error({
                  message: 'Error',
                  description: 'Activities has not been deleted',
                })
              }
            })
        },
        onCancel: () => {
          this.loadingDeletePeriode = false
        },
        centered: true,
        icon: 'warning',
        okType: 'danger',
        okText: 'Delete',
        cancelText: 'Cancel',
      })
    },
    fetchDataRewardPunishment(params = { page: 'all', order: 'ASC', search: '', sortBy: '', type: this.selectedType }) {
      this.loadingTable = true
      // this.$store.dispatch('admin/FETCH_ALL_REWARD_PUNISHMENT', { page: params.page, order: params.order, search: params.search, sortBy: params.sortBy, type: params.type })
      //   .then(res => {
      //     console.log('fetchDataRewardPunishment', res)
      //     // this.loadingTable = false
      //     // const pagination = { ...this.pagination }
      //     // pagination.total = res.total
      //     // this.pagination = pagination
      //     // this.rewardPunishmentDataTable = res.rewardPunishments.map(el => {
      //     //   return {
      //     //     key: el.id,
      //     //     ...el,
      //     //   }
      //     // })
      //   })
      this.$store.dispatch('admin/FETCH_ALL_ACTIVITIES', { page: params.page, order: params.order, search: params.search, sortBy: params.sortBy, type: params.type })
        .then(res => {
          console.log('activities ni bos', res)
          this.loadingTable = false
          const pagination = { ...this.pagination }
          pagination.total = res.total
          this.pagination = pagination
          this.rewardPunishmentDataTable = res.activities.map(el => {
            return {
              key: el.id,
              ...el,
            }
          })
        })
    },
    searchRewardPunishment() {
      if (this.search.length > 2) {
        this.fetchDataRewardPunishment({
          type: this.selectedType,
          search: this.search,
        })
      } else {
        this.fetchDataRewardPunishment({
          type: this.selectedType,
          search: this.search,
        })
      }
    },
    handleSelect(value) {
      this.fetchDataRewardPunishment({
        type: value,
      })
      this.selectedType = value
    },
  },
  mounted() {
    this.fetchDataRewardPunishment()
  },
  computed: {
    isValidateForm() {
      if (this.newActivities.name) {
        return false
      }
      return true
    },
    isMobile() {
      return this.$store.getters.isMobile
    },
    isTablet() {
      return this.$store.getters.isTablet
    },
    isDesktop() {
      return !this.isMobile && !this.isTablet
    },
  },
}
</script>
<style></style>
